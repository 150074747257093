import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useContentContext } from "@ultracommerce/react-storefront/global";
import { useElementContext } from "@ultracommerce/react-storefront/global";
import ContentLoader from "react-content-loader";

import { sortBy } from 'lodash';

const BrandPage = () => {
  const pageData = useContentContext();
  const {
    PageModule: { DynamicPage },
  } = useElementContext();
  let { pathname } = useLocation()
  const [brandData, setBrandData] = useState([] as any[])
  const [isFetching, setIsFetching] = useState(true)

  useEffect(() => {
    if (pageData.title !== 'Home' && pageData.title !== '') {
      setIsFetching(true)
      setBrandData([])
      fetch(`${process.env.REACT_APP_SEARCH_URL}/api/v1/public/search/brand/${pageData.title.replaceAll('&', '%26')}`).then(resp => resp.json()).then(data => {
        Promise.all(data.productTypeUrlTitlePaths.map(async (productType: any) => {
          return fetch(`${process.env.REACT_APP_DATASTORE_URL}/public/ultracommerce/product-type/transform/byUrlTitle/merchandise/${productType.productTypeUrlTitlePath}`).then(resp => resp.json()).then(data => ({
            error: false,
            data
          })).catch((e: any) => ({
            error: true,
            data: null
          }))
        })).then(productTypes => {
          setBrandData(productTypes.filter(productType => !productType.error).map((productType, idx) => ({
            ...productType.data,
            productCount: data.productTypeUrlTitlePaths[idx].productCount
          })))
        }).catch(e => {
          setIsFetching(false)
        }).finally(() => {
          setIsFetching(false)
        })
      }).catch(e => {
        setIsFetching(false)
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageData.title]);

  const productTypeLink = ((productType: any) => {
    return `${productType.productTypeUrlTitlePath.replace('merchandise/', '/product-type/')}?filter_brandUrlTitle=${pathname.replace('/brand/', '')}`
  })

  return (
    <DynamicPage>
      {({ renderDynamicContent }: any) => (
        <div className="container pt-4">
          {pageData && (
            <>
              <div className="brand-header mb-3">
                <div className="brand-image" style={{ backgroundImage: `url(${pageData.pageLogo})`}}></div>
                <h2 className="display-6">{pageData.title}</h2>
              </div>
              {isFetching && (
                <div className="brand-list mt-3">
                  <ContentLoader viewBox="0 0 600 80">
                    <rect x="0" y="0" rx="8" ry="8" width="1200" height="80" />
                  </ContentLoader>
                  <ContentLoader viewBox="0 0 600 80">
                    <rect x="0" y="0" rx="8" ry="8" width="1200" height="80" />
                  </ContentLoader>
                  <ContentLoader viewBox="0 0 600 80">
                    <rect x="0" y="0" rx="8" ry="8" width="1200" height="80" />
                  </ContentLoader>
                  <ContentLoader viewBox="0 0 600 80">
                    <rect x="0" y="0" rx="8" ry="8" width="1200" height="80" />
                  </ContentLoader>
                </div>
              )}
            </>
          )}
          {!isFetching && pageData &&  (
            <>
              <div className="brand-list">
                {sortBy(brandData, 'productTypeName').map(productType => {
                  return (
                    <Link to={productTypeLink(productType)} className="brand-list-item" key={productType.productTypeID}>
                      <h4>{productType.productTypeName}<span>{productType.productCount} Product{productType.productCount > 1 ? 's': ''}</span></h4>
                      <p className="product-type-name-path">{productType.productTypeNamePath}</p>
                    </Link>
                  )
                })}
              </div>
              {brandData && brandData.length === 0 && (
                <div className="alert alert-info p-4 mt-4 mb-4 text-center">We currently have no products avalable for <b>{pageData.title}</b>, please check back later.</div>
              )}
            </>
          )}
        </div>
      )}
    </DynamicPage>
  );
};

export default BrandPage;
