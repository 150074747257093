import React, { useRef, useEffect, Dispatch, SetStateAction } from "react";
import { Link } from "react-router-dom";
import { getProductRoute, useElementContext } from "@ultracommerce/react-storefront/global";
import { useSelector } from "react-redux";
import { SearchBarModel } from "@ultracommerce/react-storefront/global/src/components/Header/SearchBar/SearchBar.componentModel";
import { parseProductDescription } from "../../../utils";

export const SearchBarView = ({
  componentData: { opened, searchBoxPlaceholder, searchTerm, searched, suggestions, suggestionTypeMapping },
  setOpened,
  makeSearch,
  setSearchTerm,
}: {
  componentData: SearchBarModel;
  setOpened: (open: boolean) => void;
  makeSearch: (searchValue: string) => void;
  setSearchTerm: Dispatch<SetStateAction<string>>;
}) => {
  const {
    CommonModule: { ProductImage },
  } = useElementContext();
  const productRouting = useSelector(getProductRoute);
  const wrapperRef = useRef<HTMLFormElement>(null);
  const textInput = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (!opened) return;
    function handleClickOutside(event: MouseEvent) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        setOpened(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  return (
    <form ref={wrapperRef} className="mb-1">
      <div className="input-group input-group-lg rounded-pill flex-nowrap">
        <input
          className="form-control appended-form-control rounded-pill"
          type="text"
          ref={textInput}
          value={searchTerm}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              setOpened(false);
              makeSearch(e.currentTarget.value);
            }
          }}
          onChange={(e) => {
            e.preventDefault();
            setSearchTerm(e.target.value);
          }}
          onFocus={() => setOpened(true)}
          placeholder={searchBoxPlaceholder}
          required
        />

        {opened && searchTerm && (
          <div
            className={`p-2 predictive-search-container shadow d-flex ${
              !searched?.items?.length || !suggestions?.length ? "predictive-search-container--column" : ""
            }`}
            style={{
              borderRadius: "0 0 5px 5px",
            }}
          >
            <div>
              <div className="d-table">
                <Link
                  className="d-table-row search-prediction search-text-span"
                  to={`/shop?keyword=${searchTerm}`}
                  onClick={() => {
                    setOpened(false);
                  }}
                >
                  <span className="d-table-cell p-1 small text-nowrap">Search</span>
                  <span className="d-table-cell small">:</span>
                  <span className="d-table-cell p-1 w-100 fw-bold text-dark spelling_suggestion">{searchTerm}</span>
                </Link>
                {suggestions?.map((suggest, index) => {
                  return (
                    <Link
                      key={index}
                      className="d-table-row search-prediction search-text-span"
                      to={suggest.link}
                      onClick={() => {
                        setOpened(false);
                      }}
                    >
                      <span className="d-table-cell p-1 small text-nowrap">
                        {suggestionTypeMapping[suggest.type] || "Search"}
                      </span>
                      <span className="d-table-cell small">:</span>
                      <span className="d-table-cell p-1 w-100 fw-bold text-dark spelling_suggestion">
                        {suggest.text}
                      </span>
                    </Link>
                  );
                })}
              </div>
            </div>

            {searched?.items?.length > 0 && (
              <div className="p-2">
                <div className="d-flex flex-column pb-2">
                  {searched.items.map((item: any, idx: number, { length }: { length: number }) => {
                    const product = item.metadata;
                    const shortDescription = parseProductDescription(product.extendedProductDescription)?.shortdescription?.content
                    return (
                      <React.Fragment key={idx}>
                        <Link
                          className="products-results p-2 w-100"
                          to={`/${productRouting}/${product.urlTitle}?skuid=${product.skuID}`}
                        >
                          <div className="d-flex align-items-center">
                            <div className="col-2" style={{ paddingRight: "1rem" }}>
                              <ProductImage
                                customClass="img-fluid search-card-image"
                                imageFile={product.thumbnail}
                                skuID={product.skuID}
                              />
                            </div>
                            <div className="d-flex flex-column col-10">
                              <span className="font-weight-bold">{product.BCSManufacturerPartNumber || product.productCode}</span>
                              {shortDescription && (
                                <span className="text-truncate-multiline" style={{ WebkitLineClamp: 2 }}>
                                  {shortDescription}
                                </span>
                              )}
                            </div>

                          </div>
                        </Link>
                        {length - 1 !== idx && <hr className="m-0 category-product-divider" />}
                      </React.Fragment>
                    );
                  })}
                </div>
                <Link
                  to={`/shop?keyword=${searchTerm}`}
                  onClick={() => {
                    setOpened(false);
                  }}
                >
                  <button className="btn btn-secondary brand-navy w-100">See all Results</button>
                </Link>
              </div>
            )}
          </div>
        )}

        <button
          onClick={(e) => {
            e.preventDefault();
            textInput.current?.focus();
            makeSearch(textInput.current?.value || '')
          }}
          className="btn mb-0 px-2 search-btn"
          type="button"
        >
          <i className="bi bi-search color-black"></i>
        </button>
      </div>
    </form>
  );
};
