export interface IOptionSet {
  key: string;
  title: string;
  required: boolean;
  options: {
    title: string;
    modifier: string;
    code: string;
  }[];
}

export interface SkuConfiguration {
  options: { [key: string]: IOptionSet["options"][number] };
}

export const getProductNamePostfix = (
  options?: SkuConfiguration["options"]
) => {
  if (!options) return "";
  return Object.entries(options).reduce((acc, cur) => {
    return acc.concat(cur[1].title);
  }, "");
};

export const DEFAULT_CUSTOM_OPTIONS = {};
