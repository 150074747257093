import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  addPayment,
  disableInteractionSelector,
  eligiblePaymentMethodDetailSelector,
  fulfillmentSelector,
  getAllOrderPayments,
  getErrorMessage,
  orderPayment,
  removeOrderPayment,
  useCheckoutUtilities,
  useElementContext,
} from "@ultracommerce/react-storefront/global";
import { isStripePayment } from "@ultracommerce/react-storefront/global/src/components/Checkout/Payment/StripePayment";
import { isPaypalCommercePayment } from "@ultracommerce/react-storefront/global/src/components/Checkout/Payment/PayPalCommercePayment";

export const shouldAutoSelectPayment = (eligiblePaymentMethodDetails) => {
  return (
    eligiblePaymentMethodDetails.length === 1 &&
    (isStripePayment(eligiblePaymentMethodDetails[0]) ||
      isPaypalCommercePayment(eligiblePaymentMethodDetails[0]))
  );
};

const PaymentSlide = ({ currentStep, cartState }) => {
  const {
    CommonModule: {
      TillPayments,
      SlideNavigation,
      SwRadioSelect,
      Overlay,
      PaymentList,
      CreditCardPayment,
      TermPayment,
      PayPalPayment,
      PayPalCommercePayment,
      GiftCardPayment,
      StripePayment,
    },
  } = useElementContext();
  const disableInteraction = useSelector(disableInteractionSelector);
  const fulfillment = useSelector(fulfillmentSelector);
  const orderRequirementsList = useSelector(
    (state) => state.cart.orderRequirementsList
  );
  const eligiblePaymentMethodDetails = useSelector(
    eligiblePaymentMethodDetailSelector
  );
  const { paymentMethod } = useSelector(orderPayment);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(
    !paymentMethod?.paymentMethodID &&
      shouldAutoSelectPayment(eligiblePaymentMethodDetails)
      ? eligiblePaymentMethodDetails[0]
      : ""
  );

  const [paymentMethodOnOrder, setPaymentMethodOnOrder] = useState(false);
  const [isEditExistingPayment, setEditExistingPayment] = useState(false);
  const allPayments = useSelector(getAllOrderPayments);
  const { isFetching } = useSelector((state) => state.cart);
  const { calculatedGuestAccountFlag = false } = useSelector(
    (state) => state.userReducer
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    EXTERNAL_PAYMENT_CODE,
    CREDIT_CARD_CODE,
    GIFT_CARD_CODE,
    TERM_PAYMENT_CODE,
    PAYPAL_PAYMENT_CODE,
    CASH_PAYMENT_CODE,
    CHECK_PAYMENT_CODE,
    getPaymentMethodByIDFromList,
  } = useCheckoutUtilities();

  const processSimplePayment = (value) => {
    dispatch(
      addPayment({
        newOrderPayment: {
          saveShippingAsBilling: 1,
          paymentMethod: {
            paymentMethodID: value,
          },
        },
      })
    );
  };

  useEffect(() => {
    if (
      paymentMethod &&
      paymentMethod.paymentMethodID &&
      paymentMethodOnOrder !== paymentMethod.paymentMethodID
    ) {
      setPaymentMethodOnOrder(paymentMethod);
      setSelectedPaymentMethod(paymentMethod);
      setEditExistingPayment(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethod]);

  return (
    <Overlay
      active={isFetching}
      styles={{
        overlay: (base) => ({
          ...base,
          background: "rgba(0, 0, 0, 0)",
        }),
        spinner: (base) => ({
          ...base,
          width: "100px",
          "& svg circle": {
            stroke: "rgba(211, 211, 211)",
          },
        }),
      }}
      spinner
    >
      {/* <!-- Payment Method --> */}
      <PaymentList
        payments={allPayments}
        disableInteraction={disableInteraction}
        onRemovePayment={(paymentSelection) => {
          setPaymentMethodOnOrder("");
          setSelectedPaymentMethod("");
          setEditExistingPayment(false);
          dispatch(removeOrderPayment({ params: paymentSelection })).then(
            (response) => {
              if (
                response.isSuccess() &&
                Object.keys(response.success()?.errors || {}).length
              )
                toast.error(getErrorMessage(response.success().errors));
              else {
                if (shouldAutoSelectPayment(eligiblePaymentMethodDetails)) {
                  setSelectedPaymentMethod(eligiblePaymentMethodDetails[0]);
                }
              }
            }
          );
        }}
        onEditDetails={(existingPayment) => {
          dispatch(
            removeOrderPayment({
              params: { orderPaymentID: existingPayment.orderPaymentID },
            })
          ).then((response) => {
            if (response.isSuccess()) {
              if (Object.keys(response.success()?.errors || {}).length) {
                toast.error(getErrorMessage(response.success().errors));
              } else {
                setEditExistingPayment(false);
                if (shouldAutoSelectPayment(eligiblePaymentMethodDetails)) {
                  setSelectedPaymentMethod(eligiblePaymentMethodDetails[0]);
                }
              }
            }
          });
          const paymentToEdit = eligiblePaymentMethodDetails.find(
            (paymentMethod) =>
              paymentMethod.paymentMethodID ===
              existingPayment.paymentMethod?.paymentMethodID
          );
          if (paymentToEdit) {
            setEditExistingPayment(true);
            setPaymentMethodOnOrder(paymentToEdit.value);
            setSelectedPaymentMethod(paymentToEdit);
          }
        }}
      />
      {(allPayments.length === 0 || isEditExistingPayment) && (
        <>
          <div className="row mb-3">
            <div className="col-sm-12">
              {eligiblePaymentMethodDetails.length === 0 && (
                <div className="alert alert-warning" role="alert">
                  {t("frontend.checkout.noPaymentEnabled")}
                </div>
              )}
              {eligiblePaymentMethodDetails.length > 0 &&
                !(
                  shouldAutoSelectPayment(eligiblePaymentMethodDetails) &&
                  selectedPaymentMethod
                ) && (
                  <SwRadioSelect
                    label={t("frontend.checkout.payment.select")}
                    options={
                      !!calculatedGuestAccountFlag
                        ? eligiblePaymentMethodDetails.filter(
                            (eligiblePaymentMethod) =>
                              eligiblePaymentMethod.paymentMethodType ===
                              "creditCard"
                          )
                        : eligiblePaymentMethodDetails
                    }
                    onChange={(value) => {
                      const foundPaymentMethod = getPaymentMethodByIDFromList(
                        eligiblePaymentMethodDetails,
                        value
                      );
                      setSelectedPaymentMethod(foundPaymentMethod);
                      setEditExistingPayment(false);
                      if (
                        foundPaymentMethod.paymentMethodType ===
                          CASH_PAYMENT_CODE ||
                        foundPaymentMethod.paymentMethodType ===
                          CHECK_PAYMENT_CODE
                      ) {
                        processSimplePayment(value);
                      }
                    }}
                    selectedValue={
                      selectedPaymentMethod?.paymentMethodID?.length
                        ? selectedPaymentMethod.paymentMethodID
                        : paymentMethodOnOrder
                    }
                  />
                )}
            </div>
          </div>
          {selectedPaymentMethod.paymentMethodType === CREDIT_CARD_CODE && (
            <CreditCardPayment
              isEdit={isEditExistingPayment}
              method={selectedPaymentMethod.paymentMethodID}
              fulfillment={fulfillment}
            />
          )}
          {selectedPaymentMethod.paymentMethodType === GIFT_CARD_CODE && (
            <GiftCardPayment method={selectedPaymentMethod.paymentMethodID} />
          )}
          {selectedPaymentMethod.paymentMethodType === TERM_PAYMENT_CODE && (
            <TermPayment
              method={selectedPaymentMethod.paymentMethodID}
              fulfillment={fulfillment}
            />
          )}
          {isPaypalCommercePayment(selectedPaymentMethod) && (
            <PayPalCommercePayment
              method={selectedPaymentMethod.paymentMethodID}
              cartState={cartState}
            />
          )}
          {selectedPaymentMethod.paymentMethodType === EXTERNAL_PAYMENT_CODE &&
            selectedPaymentMethod.paymentIntegration.integrationPackage ===
              PAYPAL_PAYMENT_CODE && <PayPalPayment />}
          {selectedPaymentMethod.paymentMethodType === EXTERNAL_PAYMENT_CODE &&
            selectedPaymentMethod.paymentIntegration.integrationPackage ===
              "tillpayments" && (
              <TillPayments method={selectedPaymentMethod.paymentMethodID} />
            )}
          {isStripePayment(selectedPaymentMethod) && (
            <StripePayment
              method={selectedPaymentMethod.paymentMethodID}
              isEdit={isEditExistingPayment}
            />
          )}
        </>
      )}

      <SlideNavigation
        currentStep={currentStep}
        nextActive={!orderRequirementsList.includes("payment")}
      />
    </Overlay>
  );
};

export { PaymentSlide };
