import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFormatCurrency, getProductRoute, useElementContext } from "@ultracommerce/react-storefront/global";
import { CartLineItemModel } from "@ultracommerce/react-storefront/global/src/modules/Cart/Components/CartLineItem/CartLineItem.componentModel";

const CartLineItemView = ({
  componentData: { childBundleItems, orderItem, isDisabled, isRemovingItem, itemCount },
  setItemCount,
  onUpdateQty,
  onRemoveItem,
}: {
  componentData: CartLineItemModel;
  onRemoveItem: () => void;
  onUpdateQty: () => void;
  setItemCount: (count: number) => void;
}) => {
  const {
    ProductModule,
    CommonModule: { SimpleImage },
  } = useElementContext();
  const [formatCurrency] = useFormatCurrency({});
  const { t } = useTranslation();
  const productRouting = useSelector(getProductRoute);

  return (
    <div className="row py-3 cart-item-box">
      {orderItem.errorMsg && (
        <div>
          <p className="text-danger">{orderItem.errorMsg}</p>
        </div>
      )}
      <div className="row detail">
        <div className="col-sm-2 col-3 image">
          {orderItem.sku.images && orderItem.sku.images?.length > 0 && (
            <SimpleImage
              className="img-fluid  m-auto image_container productImage"
              src={orderItem.sku.images?.at(0)}
              alt={orderItem.sku.product.productName}
              type="product"
            />
          )}
        </div>
        <div className="col-sm-4 col-9">
          <div className="title">
            <h4>
              <Link
                to={{
                  pathname: `/${productRouting}/${orderItem.sku.product.urlTitle}`,
                  // @ts-ignore
                  state: { ...orderItem.sku.product },
                }}
                className="text-decoration-none text-dark"
              >
                {orderItem.sku.product.productName}
              </Link>
            </h4>
          </div>
          <div className="font-size-sm item-sku">
            <span className="mr-2">
              {t("frontend.product.sku")} {orderItem.sku.skuCode}
            </span>
          </div>
        </div>
        <div className="col-sm-12 col-md-6 d-flex bottom-detail-box d-none d-md-block">
          <div className="row">
            <div className="item-price col-sm-3">
              <ProductModule.CoreComponents.ProductPrice
                type="cart"
                salePrice={orderItem.extendedUnitPriceAfterDiscount}
                listPrice={Math.max(orderItem.sku.listPrice, orderItem.extendedUnitPrice)}
              />
            </div>
            {!isDisabled ? (
              <div className="number-range col-sm-4">
                <input
                  type="number"
                  className=""
                  value={itemCount}
                  disabled={isRemovingItem && !!orderItem.sku.skuID}
                  onChange={(e) => {
                    setItemCount(parseInt(e.target.value, 10));
                  }}
                />
                <button className="btn text-muted btn-link p-1 text-end" onClick={onUpdateQty}>
                  {t("frontend.account.cart.item.updateQuantity")}
                </button>
              </div>
            ) : (
              <div className="col-sm-4">
                <small>{t("frontend.cart.quantity")}</small> {orderItem.quantity}
              </div>
            )}
            <div className="col-sm-3 p-0">
              <h5 className="total-price">{formatCurrency(orderItem.extendedPriceAfterDiscount)}</h5>
            </div>
            <div className="item-delete-btn col-sm-2 p-0">
              <span className={` ${isRemovingItem ? "pe-none" : "clickable"}`} onClick={onRemoveItem}>{t('frontend.core.remove')}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        {childBundleItems?.map((childBundleItem, key) => {
          return (
            <div className="col-3 d-flex" key={childBundleItem.orderItemID}>
              {key !== 0 ? (
                <i className="bi bi-plus-circle col-2 align-self-center"></i>
              ) : (
                <div className="col-2"></div>
              )}
              <Link className="col-10" to={`/${productRouting}/${childBundleItem.sku.product.urlTitle}`}>
                <SimpleImage
                  className="img-fluid  m-auto image_container productImage border border-light"
                  src={childBundleItem.sku.images?.at(0)}
                  alt={childBundleItem?.sku?.product?.productName}
                  type="product"
                />
                <span className="text-dark">
                  {` ${formatCurrency(childBundleItem.price)} x ${childBundleItem.quantity}`}
                </span>
                <p>{childBundleItem?.sku?.product?.productName}</p>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export { CartLineItemView };
