import { useFormatCurrency } from "@ultracommerce/react-storefront/global";
import { ProductPriceModel } from "@ultracommerce/react-storefront/global/src/modules/Product/Components/ProductPrice/ProductPrice.componentModel";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

type AdditionalComponentData = {
  isAuthenticated: boolean;
};

const ProductPriceView = ({
  componentData: {
    showPriceType,
    salePrice,
    listPrice,
    noPriceText,
    pricingPostText,
    pricingPreText,
  },
  additionalComponentData: { isAuthenticated },
}: {
  componentData: ProductPriceModel;
  additionalComponentData: AdditionalComponentData;
}) => {
  const [formatCurrency] = useFormatCurrency({});
  const { t } = useTranslation();
  const [isInfoOpen, setIsInfoOpen] = useState(false);

  if (showPriceType === "showLoginToViewPrice")
    return (
      <div
        className="alert alert-warning fs-6 productPrice_warning--loginToView"
        role="alert"
      >
        {t("frontend.product.loginToView")}
        <br />
        <Link to="/my-account/login"> {t("frontend.account.login")} </Link>
      </div>
    );

  if (showPriceType === "showVerifyForPricing")
    return (
      <div
        className="alert alert-warning fs-6 productPrice_warning--verifyForPrice"
        role="alert"
      >
        {t("frontend.product.verifyForPricing")}
      </div>
    );

  if (showPriceType === "showMissingPrice")
    return (
      <div className="productPrice">
        <strong>{noPriceText}</strong>
      </div>
    );

  return (
    <div className="productPrice">
      <div className="productPrice_listingWrapper">
        <span className="productPrice_preText">{pricingPreText} </span>
        <span className="productPrice_price">
          {/*show list price only when list price is more than sale price */}
          {salePrice < listPrice ? (
            <>
              <s className="productPrice_listPrice">{`${formatCurrency(
                listPrice
              )}`}</s>
              <span className="text-primary productPrice_salePrice">
                {formatCurrency(salePrice)}
              </span>
            </>
          ) : (
            <span className="text-primary productPrice_listPrice">{`${formatCurrency(
              listPrice
            )}`}</span>
          )}
        </span>
        <span className="productPrice_postText">{pricingPostText}</span>
        {!isAuthenticated && (
          <i
            className="bi bi-info-circle-fill"
            onClick={() => setIsInfoOpen(!isInfoOpen)}
            role="button"
          ></i>
        )}
        {isInfoOpen && (
          <div>
            Please sign in to your account to see your pricing, which may be
            different from the price displayed.
          </div>
        )}
      </div>
    </div>
  );
};

export { ProductPriceView };
