import { ProductImage } from "@ultracommerce/react-storefront/src/interface/ProductImageGallery";

export interface ProductImageGalleryProps {
  imageGallery: ProductImage[];
  skuID?: string;
  imageFile?: string;
}

export class ProductImageGalleryModel {
  filterImages: ProductImage[] = [];

  constructor({
    props: { imageGallery, skuID, imageFile },
  }: {
    props: ProductImageGalleryProps;
  }) {
    if (imageGallery.length) {
      this.filterImages = imageGallery?.filter(
        ({ assignedSkuIDList = "", resizedImagePaths = [], type }) => {
          return (
            (type === "skuDefaultImage" ||
              type === "productAlternateImage" ||
              (skuID && assignedSkuIDList.includes(skuID))) &&
            resizedImagePaths?.at(0) &&
            !resizedImagePaths.at(0)?.includes("missingimage")
          );
        }
      );
    }
    if (this.filterImages.length === 0) {
      this.filterImages = [
        { originalPath: "", name: "", resizedImagePaths: ["", "", ""] },
      ];
    }

    const productImageFile = this.filterImages
      .splice(
        this.filterImages.findIndex(
          (item) => item.originalFilename === imageFile
        ),
        1
      )
      ?.at(0);
    if (productImageFile) this.filterImages.unshift(productImageFile);
    this.filterImages = this.filterImages.reverse();
  }
}
