import { useComponentData } from "@ultracommerce/react-storefront/global/src/modules/Product/Components/ProductPrice/ProductPrice.componentService";
import { ProductPriceProps } from "@ultracommerce/react-storefront/global/src/modules/Product/Components/ProductPrice/ProductPrice.componentModel";
import { ProductPriceView } from "./ProductPrice.componentView";
import { isAuthenticated } from "@ultracommerce/react-storefront/global";

const ProductPrice = (props: ProductPriceProps) => {
  const { componentData } = useComponentData(props);
  componentData.pricingPreText = isAuthenticated() ? "Your Price" : "Web Price";
  return (
    <ProductPriceView
      componentData={componentData}
      additionalComponentData={{
        isAuthenticated: isAuthenticated() ? true : false,
      }}
    />
  );
};

export { ProductPrice, type ProductPriceProps };
