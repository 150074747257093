export interface RelatedProductTypeSliderProps {
  productID: string;
  slidesToShow?: number;
}

export class RelatedProductTypeSliderModel {
  slidesToShow: number = 4;
  isLoaded: boolean = false;
  productTypes: string[];
  constructor({
    props,
    isLoaded,
    productTypes,
  }: {
    props: RelatedProductTypeSliderProps;
    isLoaded: boolean;
    productTypes:string[];
  }) {
    this.slidesToShow = props.slidesToShow || 4;
    this.isLoaded = isLoaded;
    this.productTypes = productTypes;
  }
}
