import { ContentElement } from "./FeaturedCategoryListingsType";
import Slider from "react-slick";
import "./FeatureCategoryListings.scss";
const FeatureCategoryListings = (props: ContentElement) => {
  const { innerElements, contentElementName } = props;

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: innerElements.length > 3 ? 4 : innerElements.length,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: innerElements.length > 2 ? 3 : innerElements.length,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="category-container">
      <header className="section-title">
        <h2>{contentElementName}</h2>
      </header>
      <div className="category-card border-0 bg-transparent">
        <Slider {...settings}>
          {innerElements.map((innerElement) => (
            <div className="category-repeater" key={innerElement.id}>
              <div className="category-card-body h-100 mx-4 pointer">
                <a
                  href={innerElement.linkTo}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={innerElement.media}
                    alt={innerElement.text ?? "Category Image"}
                    className="img-fluid category-image"
                  />
                </a>
                {innerElement.text && (
                  <h3 className="text-center">{innerElement.text}</h3>
                )}
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default FeatureCategoryListings;
