import { useCallback, useEffect } from "react";
import { ProductFormView } from "./ProductForm.componentView";
import { Quote } from "@ultracommerce/react-storefront/global/src/interface/Quote";
import { ProductFormProps } from "@ultracommerce/react-storefront/global/src/modules/Product/Components/ProductForm/ProductForm.componentModel";
import { useComponentModel } from "@ultracommerce/react-storefront/global/src/modules/Product/Components/ProductForm/ProductForm.componentService";

const ProductForm = (
  props: ProductFormProps
) => {
  const { componentData, componentService } = useComponentModel(props);
  const { getAllCartOrQuotes } = componentService;
  useEffect(() => {
    if (componentData.authenticationRequiredForAddToCart) return;
    getAllCartOrQuotes();
  }, [componentData.authenticationRequiredForAddToCart, getAllCartOrQuotes]);
  const skuConfiguration = props.skuConfiguration;

  const addToCartOrQuote = useCallback(
    (quote?: Quote) => {
      if (componentData.isLoading) return;
      componentService.addToQuote(
        componentData.sku,
        componentData.itemCount,
        quote,
        skuConfiguration
      );
    },
    [
      componentData.isLoading,
      componentData.itemCount,
      componentData.sku,
      componentService,
      skuConfiguration,
    ]
  );

  return (
    <ProductFormView
      viewData={{ ...componentData }}
      {...componentService}
      addToCartOrQuote={addToCartOrQuote}
    />
  );
};

export { ProductForm, type ProductFormProps };
