import axios from "axios";
import { useEffect, useState } from "react";
const getSdkURL = () =>
  `${window.UC?.integrations?.commerce.moduleApiUrl || ""}/`;

const useLivePrice = ({ sku, quantity = 1, currencyCode }) => {
  let [isFetching, setFetching] = useState(true);
  const [livePrice, setLivePrice] = useState({
    livePriceFlag: false,
    skuPrice: sku?.salePrice,
    skuCode: sku?.skuCode,
  });
  useEffect(() => {
    let source = axios.CancelToken.source();
    axios({
      method: "POST",
      withCredentials: true,
      url: `${getSdkURL()}api/scope/getSkuPriceByQuantity`,
      headers: {
        "Content-Type": "application/json",
      },
      data: { skuID: `${sku.skuID}`, quantity, currencyCode },
    })
      .then((response) => {
        if (
          response?.status === 200 &&
          response?.data?.failureActions.length === 0
        ) {
          setLivePrice(response?.data);
          setFetching(false);
          return response?.data;
        }
      })
      .catch(() => {
        setFetching(false);
      });
    return () => {
      source.cancel();
    };
  }, [sku.skuID, quantity, currencyCode]);

  return {
    isFetchingLivePrice: isFetching,
    salePrice: livePrice?.price || sku?.salePrice,
    listPrice: livePrice?.listPrice || sku?.listPrice,
    inventoryLocations: livePrice?.inventoryData,
    QATS: livePrice?.QATS,
    QOH: livePrice?.QOH,
    restrictedFlag: livePrice?.restrictedProductFlag,
    skuAllowBackorderFlag: livePrice?.skuAllowBackorderFlag,
  };
};
export { useLivePrice };
