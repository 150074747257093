import axios from 'axios'
import { useEffect, useState } from 'react'
const getSdkURL = () => `${window.UC?.integrations?.commerce.moduleApiUrl || ""}/`;

const useOrderInvoicePdf = () => {
  let [isFetching, setFetching] = useState(true)
  const [base64PDF, setBase64PDF] = useState('')
  const [orderID, setOrderID] = useState('')
  useEffect(() => {
    if (orderID.length > 0) {
      let source = axios.CancelToken.source()
      axios({
        method: 'POST',
        withCredentials: true,
        url: `${getSdkURL()}api/scope/getOrderInvoicePDF`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: { orderID: `${orderID}` },
      })
        .then(response => {
          if (response?.status === 200) {
            setFetching(false)
            setBase64PDF(response?.data?.printContent)
          }
        })
        .catch(() => {
          setFetching(false)
        })
      return () => {
        source.cancel()
      }

    }
  }, [orderID])

  return { base64PDF, isFetching, setOrderID }

}
export { useOrderInvoicePdf }