import { useEffect, useMemo, useState } from "react";
import {
  RelatedProductTypeSliderModel,
  RelatedProductTypeSliderProps,
} from "./RelatedProductTypeSlider.componentModel";
import { axios, getSdkURL } from "@ultracommerce/react-storefront/global";
 
export const useComponentData = (props: RelatedProductTypeSliderProps) => {
  const { productID } = props;
 
  const [productTypes, setProductTypes] = useState<string[]>([]);
  const [isLoaded, setLoaded] = useState(false);
 
  useEffect(() => {
    let didCancel = false;
    setLoaded(false);
 
    axios({
      method: "GET",
      withCredentials: true,
      url: `${getSdkURL()}api/scope/getRelatedProductTypeUrlTitlePaths`,
      params: { productID: productID },
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response: any) => {
      if (didCancel) return;
      if (response && !response.data?.failureActions.length) {
        if (response.data.relatedProductTypeUrlTitlePaths.length) {
          response.data.relatedProductTypeUrlTitlePaths.forEach(
            (path: string) => {
              fetch(
                `${process.env.REACT_APP_DATASTORE_URL}/public/ultracommerce/product-type/transform/byUrlTitle/${path}`
              )
                .then((resp) => resp.json())
                .then((data) => {
                  setProductTypes((prevState) => [...prevState, data]);
                });
            }
          );
        }
        setLoaded(true);
      }
    });
 
    return () => {
      didCancel = true;
    };
  }, [productID]);
 
  const componentData = useMemo(
    () => new RelatedProductTypeSliderModel({ props, productTypes, isLoaded }),
    [isLoaded, productTypes, props]
  );
 
  return { componentData };
};
 