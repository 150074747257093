import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import {
  SlatwalApiService,
  getErrorMessage,
  getWishLists,
  isImpersonating,
  receiveCart,
  receiveUser,
  useElementContext,
} from "@ultracommerce/react-storefront/global";
import { useNavigate } from "react-router";

const AccountImpersonation = ({ crumbs, title, customBody, contentTitle }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    CommonModule: { AccountLayout, AccountContent },
  } = useElementContext();
  
  const impersonateAccount = (user) => {
    return SlatwalApiService.account
      .impersonateAccount(user)
      .then((response) => {
        if (
          response.isSuccess() &&
          Object.keys(response.success()?.errors || {}).length
        )
          toast.error(getErrorMessage(response.success().errors));
        if (response.isSuccess()) {
          toast.success(t("frontend.account.start_impersonation_successful"));
          dispatch(receiveUser(response.success().account));
          dispatch(receiveCart(response.success().cart));
          dispatch(getWishLists(true));
          navigate('/');
        } else {
          toast.error(t("frontend.account.start_impersonation_failed"));
        }
        return response;
      });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      emailAddress: "",
    },
    onSubmit: (values, { setSubmitting }) => {
      impersonateAccount({
        emailAddress: values.impersonateEmailAddress,
        returnJSONObjects: "account,cart",
      });
      setSubmitting(false);
    },
  });
  return (
    <AccountLayout crumbs={crumbs} title={title}>
      <AccountContent customBody={customBody} contentTitle={contentTitle} />
      <div className="row">
        <div className="col-sm-12">
          <h4 className="text-left my-lg-4">
            {t("frontend.account.account_impersonation")}
          </h4>
        </div>
        {!isImpersonating() && (
          <form onSubmit={formik.handleSubmit} className="col-12">
            <div className="col-sm-6">
              <div className="form-group">
                <label htmlFor="impersonateEmailAddress">
                  {t("frontend.account.customer_email")}
                </label>
                <input
                  className="form-control"
                  required="true"
                  type="email"
                  id="impersonateEmailAddress"
                  value={formik.values.impersonateEmailAddress}
                  onChange={formik.handleChange}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="d-flex flex-wrap justify-content-end">
                <button
                  type="submit"
                  disabled={formik.isSubmitting}
                  className="btn btn-primary mt-3"
                >
                  {t("frontend.account.start_impersonation")}
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
    </AccountLayout>
  );
};

export { AccountImpersonation };
