import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import { RelatedProductTypeSliderModel } from "./RelatedProductTypeSlider.componentModel";
import { getProductTypeRoute } from "@ultracommerce/react-storefront/global";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const RelatedProductTypeSliderView = ({ componentData }: { componentData: RelatedProductTypeSliderModel }) => {
  const { t } = useTranslation();
  const { slidesToShow, productTypes, isLoaded } = componentData;
  const productTypeRoute = useSelector(getProductTypeRoute);
  if (!productTypes.length) {
    return null;
  }

  const settings = {
    dots: false,
    infinite: productTypes && productTypes.length >= slidesToShow,
    // infinite: true,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <section className="content-spacer">
      {productTypes.length > 0 ? (
        <>

          <div className="container">
            <header className="section-title pb-2">
              <h2 className="mb-5">{t("frontend.product.relatedProductType")}</h2>
            </header>
            <div className="card border-0 bg-transparent">
              <Slider {...settings}>
                {isLoaded &&
                  productTypes.map((product: any) => {
                    return (
                      <div className="repeater" key={product.productCode}>
                        <div className="card border-0">
                          <Link
                            to={`/${productTypeRoute}/${product.urlTitle}`}
                            onClick={() =>
                              window.scrollTo({
                                top: 0,
                                behavior: "smooth",
                              })
                            }
                            className="d-block overflow-hidden rounded-lg"
                          >
                            <div
                              style={{ backgroundImage: `url(${product.imageFile})` }}
                              className="productImage productTypeImage d-block w-100"></div>
                          </Link>
                          <div className="card-body py-0 mb-2">
                            <h4 className="productType-title text-center">
                              <Link
                                to={`/${productTypeRoute}/${product.urlTitle}`}
                                className="link-button"
                                onClick={() =>
                                  window.scrollTo({
                                    top: 0,
                                    behavior: "smooth",
                                  })
                                }
                              >
                                {product.productTypeName}
                              </Link>
                            </h4>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </Slider>
            </div>
          </div>
        </>) : ''}
    </section>
  );
};
export { RelatedProductTypeSliderView };
