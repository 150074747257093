import {
  useUtilities,
  useElementContext,
} from "@ultracommerce/react-storefront/global";
const ActionBanner = ({
  contentHeading,
  contentSubHeading,
  contentBody = "",
  linkLabel = "",
  linkUrl = "",
  style,
  bootStrapLayoutClass,
  ctaBackgroundImage,
  urlTarget,
  ctaButtonType,
  customStyleClasses,
}) => {
  const {
    CommonModule: { CallToActionBtn },
  } = useElementContext();
  let { eventHandlerForWSIWYG } = useUtilities();
  if (customStyleClasses && customStyleClasses.includes("[")) {
    try {
      const customStyleClassesArray = JSON.parse(customStyleClasses);
      customStyleClasses = customStyleClassesArray.join(" ");
    } catch (e) {
      console.error("Error converting customStyleClasses", e);
    }
  }
  return (
    <div
      className={`py-4 action-banner-sec ${style.toLowerCase()} ${bootStrapLayoutClass} ${
        customStyleClasses || ""
      }`}
      style={
        ctaBackgroundImage
          ? {
              backgroundImage: `url(${ctaBackgroundImage})`,
              backgroundSize: "cover",
            }
          : {}
      }
    >
      <div className="container">
        <div className="row">
          <div className="col-0 col-md-2" />
          <div className="col-md-8 text-center">
            {!!contentHeading && (
              <header className="section-title">
                <h2>{contentHeading}</h2>
                <h5>{contentSubHeading}</h5>
              </header>
            )}
            <div
              onClick={eventHandlerForWSIWYG}
              dangerouslySetInnerHTML={{ __html: contentBody }}
            />
            {!!linkLabel && (
              <CallToActionBtn
                urlTarget={urlTarget}
                linkLabel={linkLabel}
                linkUrl={linkUrl}
                ctaButtonType={ctaButtonType}
              />
            )}
          </div>
          <div className="col-0 col-md-2" />
        </div>
      </div>
    </div>
  );
};

export { ActionBanner };
