export const parseProductDescription = (
    productDescription: string = "",
    specifications: any = ""
) => {
    let parsed: any = {};

    if (productDescription) {
        let splitsections = productDescription.includes('\r\n') ? productDescription
            ?.split("||")
            .map((item: string) => item.split("\r\n")) : productDescription
                ?.split("||")
                .map((item: string) => item.split("\n"));

        if (!splitsections.length) return productDescription;

        splitsections.forEach((section: any) => {
            if (!section[1]) return; //abort is there is no content
            const normailsedSectionProperty = section[0]
                .toLowerCase()
                .replace(/\W/g, "");
            parsed[normailsedSectionProperty] = {
                title: section[0],
                content: section[1],
            };
        });
    }

    if (parsed?.featuresbenefits)
        parsed.featuresbenefits.content =
            parsed.featuresbenefits.content.split(" | ");

    if (parsed.relateddocuments) {
        parsed.relateddocuments.content = parsed.relateddocuments.content
            .split("\r\n")
            .map((item: any) => {
                return { title: item.split("|")[0], link: item.split(" | ")[1] };
            });
    }

    if (specifications) {
        parsed.specifications = { title: "", content: "" };
        parsed.specifications.title = "Specifications";
        parsed.specifications.content = specifications.includes('\r\n') ?
            specifications.split("\r\n")
                .map((item: any) => {
                    return { key: item.split(" | ")[0], value: item.split(" | ")[1] };
                }) :
            specifications.split("\n")
                .map((item: any) => {
                    return { key: item.split(" | ")[0], value: item.split(" | ")[1] };
                })
    }

    return parsed;
}