import { PageModule } from "@ultracommerce/react-storefront/global";

export const routerPathSelector = (state: any, urlType: string, defaultUrl: string) => {
  const route = state.configuration.router.find(({ URLKeyType }: { URLKeyType: string }) => URLKeyType === urlType);
  if(urlType === 'ProductType') {
    return `/${route?.URLKey || defaultUrl}/*`;  
  }
  return `/${route?.URLKey || defaultUrl}/:id`;
};

interface IRouteInterface {
  [key: string]: {
    elementKey: keyof typeof PageModule;
    pathSelector?: (state: any) => string;
    props?: any;
    hideSelector?: (state: any) => boolean;
  };
}

export const RouteInterface: IRouteInterface = {
  Product: {
    elementKey: "ProductDetailsPage",
    pathSelector: (state: any) => routerPathSelector(state, "Product", "product"),
  },
  ProductType: {
    elementKey: "ProductType",
    pathSelector: (state: any) => routerPathSelector(state, "ProductType", "product-type"),
  },
  Category: { elementKey: "Category", pathSelector: (state: any) => routerPathSelector(state, "Category", "category") },
  Brand: { elementKey: "Brand", pathSelector: (state: any) => routerPathSelector(state, "Brand", "brand") },
  Manufacturer: {
    elementKey: "Manufacturer",
    pathSelector: (state: any) => state.configuration.shopByManufacturer.slug,
  },
  BlogPost: {
    elementKey: "BlogPost",
    pathSelector: (state: any) => `/${state.configuration.site.settings.blogPageSlug}/:id`,
  },
  Blog: { elementKey: "Blog", pathSelector: (state: any) => `/${state.configuration.site.settings.blogPageSlug}` },
  Shop: { elementKey: "Shop", pathSelector: () => `/shop` },
  Range: { elementKey: "ProductType", pathSelector: () => `/range/:id` },
  Event: { elementKey: "Event", pathSelector: () => `/event/:id` },
  Location: { elementKey: "Location", pathSelector: () => `/location/:id` },
  BulkOrder: { elementKey: "BulkOrder", pathSelector: () => `/bulkorder` },
  MyAccount: { elementKey: "MyAccount", pathSelector: () => `/my-account/*` },
  Checkout: {
    elementKey: "Checkout",
    pathSelector: () => `/checkout/*`,
    hideSelector: (state) => state.configuration.site.settings.sitePurpose === "Non-Transactional",
  },
  Cart: {
    elementKey: "Cart",
    pathSelector: () => `/shopping-cart`,
    hideSelector: (state) => state.configuration.site.settings.sitePurpose === "Non-Transactional",
  },
  Quote: {
    elementKey: "Quote",
    pathSelector: () => `/quotes-in-cart`,
    hideSelector: (state) => state.configuration.site.settings.sitePurpose === "Non-Transactional",
  },
  OrderConfirmation: {
    elementKey: "OrderConfirmation",
    pathSelector: () => `/order-confirmation`,
    hideSelector: (state) => state.configuration.site.settings.sitePurpose === "Non-Transactional",
  },
  OrderTemplateCart: {
    elementKey: "OrderTemplateCart",
    pathSelector: () => `/scheduled-delivery-cart`,
    hideSelector: (state) => state.configuration.site.settings.sitePurpose === "Non-Transactional",
  },
  DynamicPage: { elementKey: "DynamicPage", pathSelector: () => `*` },
  "404": { elementKey: "DynamicPage", pathSelector: () => "/404" },
  Index: { elementKey: "DynamicPage", props: { index: true } },
};

export class RouteService {
  routeInterface: IRouteInterface = RouteInterface;
  constructor({ routeInterface = RouteInterface } = {}) {
    this.routeInterface = routeInterface;
  }

  genRoutes = (state: any): { path?: string; elementKey: keyof typeof PageModule; props?: any }[] => {
    const routes = Object.entries(this.routeInterface)
      .filter(([_, routeData]) => !routeData.hideSelector || !routeData.hideSelector(state))
      .map(([_, routeData]) => ({
        path: routeData.pathSelector?.(state),
        elementKey: routeData.elementKey,
        props: routeData.props,
      }))
      .sort((a, b) => (b.path?.split("/").length || 0) - (a.path?.split("/").length || 0));
    return routes;
  };
}
