import { useElementContext, useUtilities } from "@ultracommerce/react-storefront/global";
import { Location } from "@ultracommerce/react-storefront/global/src/interface/Location";
import { LocationDetail } from "./LocationDetail";

interface LocationCardProps {
  location: Location;
}

const LocationCard = ({ location }: LocationCardProps) => {
  const { LocationModule } = useElementContext();
  let { eventHandlerForWSIWYG } = useUtilities();

  return (
    <div>
      <LocationModule.CoreControl.LocationHeader title={''} thumbnail={location.featuredImage} />
      <div className="p-5 container">
        <div
          className="pb-4"
          onClick={eventHandlerForWSIWYG}
          dangerouslySetInnerHTML={{ __html: location.Description }}
        />
        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="w-100 position-relative" style={{ paddingBottom: "50%" }}>
              <div className="position-absolute top-0 left-0 w-100 h-100">
                <LocationModule.CoreControl.LocationMap
                  center={{
                    lat: parseFloat(location.AddressLatitude),
                    lng: parseFloat(location.AddressLongitude),
                  }}
                  locations={[
                    {
                      lat: parseFloat(location.AddressLatitude),
                      lng: parseFloat(location.AddressLongitude),
                      title: location.CustomerName,
                      address: location.PhysicalAddress1,
                      phoneNo: location.PhoneNo,
                    },
                  ]}
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 d-flex flex-column">
            <LocationDetail
              customerName={location.CustomerName}
              address={location.PhysicalAddress1}
              city={location.PhysicalCity}
              postcode={location.PhysicalPostcode}
              locationType={location.locationType}
              websiteURL={location.WebsiteURL}
              faxNo={location.FaxNo}
              phoneNo={location.PhoneNo}
              state={location.PhysicalState}
            />
            {!!location.locationFeatures && (
              <div className="mt-auto">
                <LocationModule.CoreControl.LocationFeatures features={location.locationFeatures.split(",")} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export { LocationCard, type LocationCardProps };
