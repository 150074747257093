import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useElementContext } from "@ultracommerce/react-storefront/global/src/contexts/ElementContext";

import { useFormik } from "formik";
import { toast } from "react-toastify";
import { SlatwalApiService } from "@ultracommerce/react-storefront/global/src/services/SlatwalApiService.js";
import "@ultracommerce/react-storefront/global/src/services/AxiosService.js";
import "query-string";
import "@ultracommerce/react-storefront/global/src/services/productService.class.js";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { getErrorMessage } from "@ultracommerce/react-storefront/global/src/utils/index.js";
import { useDispatch, useSelector } from "react-redux";
import { receiveUser } from "@ultracommerce/react-storefront/global/src/actions/userActions.js";
import { receiveCart } from "@ultracommerce/react-storefront/global/src/actions/orderActions.js";
import "@ultracommerce/react-storefront/global/src/actions/configActions.js";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import { getSdkURL } from "@ultracommerce/react-storefront/global";
import { useState } from "react";

const useCreateGuestAccount = (validRecaptcha) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const signupSchema = Yup.object().shape({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    emailAddress: Yup.string().email().required("Required"),
  });
  const formik = useFormik({
    initialValues: {
      returnTokenFlag: "1",
      firstName: "",
      lastName: "",
      emailAddress: "",
      returnJSONObjects: "account,cart",
    },
    validateOnChange: false,
    validationSchema: signupSchema,
    onSubmit: function onSubmit(values) {
      if (!validRecaptcha) {
        toast.error("Verify Recaptcha");
      } else {
        SlatwalApiService.account
          .createGuestAccount(values)
          .then(function (response) {
            var _response$success;
            if (
              response.isSuccess() &&
              Object.keys(
                ((_response$success = response.success()) === null ||
                _response$success === void 0
                  ? void 0
                  : _response$success.errors) || {}
              ).length
            )
              toast.error(getErrorMessage(response.success().errors));
            if (response.isSuccess()) {
              if (!response.success().failureActions.length) {
                toast.success("Success");
                dispatch(receiveUser(response.success().account));
                dispatch(receiveCart(response.success().cart));
                navigate("/checkout");
              }
            } else {
              toast.error("Error");
            }
          });
      }
    },
  });
  return {
    formik: formik,
  };
};

const CreateGuestAccount = () => {
  const {
    CommonModule: { PromptLayout, SWForm, SWInput },
  } = useElementContext();

  const { t } = useTranslation();
  const recaptchaSitekey = useSelector(state => state.configuration.site.settings.siteStorefrontConfig.siteRecaptchaSiteKey)
  const [validRecaptcha, setValidRecaptcha] = useState(false);
  const { formik } = useCreateGuestAccount(validRecaptcha);
  const onChange = (recaptchaToken) => {
    axios({
      method: "POST",
      withCredentials: true,
      url: `${getSdkURL()}api/scope/verifyRecaptcha`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        recaptchaToken,
      },
    }).then((response) => {
      if (response?.status === 200 && response?.data?.captchaResult) {
        setValidRecaptcha(true);
      }
    });
  };

  const onExpired = () => {
    setValidRecaptcha(false);
  };

  return (
    <PromptLayout>
      <h2>{t("frontend.account.guest.checkout")}</h2>
      <p>
        {t("frontend.account.old_account")}
        <Link className="ms-1 link" to="/my-account/login">
          {t("frontend.account.here")}
        </Link>
        .
      </p>

      <SWForm formik={formik} primaryButtontext="Continue" title="">
        <div className="row">
          <div className="col-md-6">
            <SWInput
              formik={formik}
              token="firstName"
              label={t("frontend.account.create.guest.account.firstName")}
              wrapperClasses=""
            />
          </div>
          <div className="col-md-6">
            <SWInput
              formik={formik}
              token="lastName"
              label={t("frontend.account.create.guest.account.lastName")}
              wrapperClasses=""
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <SWInput
              formik={formik}
              token="emailAddress"
              label={t("frontend.account.create.guest.account.emailAddress")}
              wrapperClasses=""
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <ReCAPTCHA
              sitekey={recaptchaSitekey}
              onChange={onChange}
              onExpired={onExpired}
            />
          </div>
        </div>
      </SWForm>
    </PromptLayout>
  );
};

export { CreateGuestAccount };
