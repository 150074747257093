const sitePreload = {
  global: {
    globalVATCountries: "GB,IE,AD",
    globalTimeFormat: "hh:mm tt",
    globalDateFormat: "mmm dd, yyyy",
    globalURLKeyBrand: "sb",
    globalURLKeyProductType: "spt",
    globalURLKeyAccount: "ac",
    globalURLKeyAttribute: "att",
    globalURLKeyAddress: "ad",
    globalURLKeyCategory: "caty",
    globalURLKeyProduct: "sp",
    baseFilePath: "",
  },
  currencies: {
    USD: {
      currencySymbol: "$",
      formatMask: " ",
    },
  },
  sites: [],
  site: {
    customAttributes: { cmsUrl: "http://localhost" },
    currencyCode: "USD",
    settings: {
      siteDefaultCountry: "us",
    },
  },
  router: [
    { URLKeyType: "Product", URLKey: "product" },
    { URLKeyType: "ProductType", URLKey: "product-type" },
    { URLKeyType: "Category", URLKey: "category" },
    { URLKeyType: "Brand", URLKey: "brand" },
    { URLKeyType: "Account", URLKey: "my-account" },
    { URLKeyType: "Address", URLKey: "ad" },
    { URLKeyType: "Attribute", URLKey: "att" },
  ],
  blog: {
    url: "blog",
  },
  cmsProvider: "slatwallCMS",
  enforceVerifiedAccountFlag: false,
  allowGuestCheckout: true,
  products: {
    fallbackImageCall: false,
    loginRequiredForPrice: false,
    dropdownLimit: 20,
    quantityInput: "text", // [ text|dropdown]
  },
  productPrice: {
    checkInvetory: false,
    showPriceForUnverifiedAccounts: true,
  },
  defaultCardConfiguration: {
    input: {
      showCriteria: "none",
      label: "",
    },
    showPrice: false,
    showBrand: true,
    buttons: [
      {
        listingButtonLabel: "Add To Cart",
        listingButtonDisplayCriteria: "all",
        disableListingButton: false,
        showAuthenticationRequiredMessageFlag: false,
        hideListingButton: true,
        type: "ADD_TO_CART",
      },
    ],
  },
  defaultListingConfig: {
    showInput: true,
    viewMode: "GRID",
    viewModeOptions: ["GRID"],
    forcedFilterOptions: ["productType_slug", "brand_slug", "category_slug"],
    requiredPreFilters: [],
    headings: [
      {
        heading: "Product Name",
        value: "product_productName",
      },
      {
        heading: "Sku Code",
        value: "sku_skuCode",
      },
    ],
    buttonLabel: "frontend.product.add_to_cart",
    params: {
      propertyIdentifierList: "",
      includeSKUCount: true,
      includeResizedImages: false,
      applyStockFilter: false,
      productsListingFlag: false,
      includeSettings: true,
      includePagination: true,
      includePotentialFilters: true,
    },
    filters: {
      brand_slug: "",
      orderBy: "",
      pageSize: "12",
      currentPage: "1",
      keyword: "",
      productType_slug: "",
      category_slug: "",
    },
    returnFacetListWithFilter: "brand,option,category,attribute,sorting,priceRange,productType",
  },
  myAccount: {
    mostRecentCount: 3,
  },
  shopByManufacturer: {
    slug: "/brands",
    showInMenu: true,
    gridSize: 3,
    maxCount: 12,
  },
  seo: {
    title: "Slatwall",
    titleMeta: "",
  },
  filtering: {
    productTypeBase: "merchandise",
    requireKeyword: true,
    filterDataShowCounts: 5,
  },

  footer: {
    formLink: "",
  },
  theme: {
    themeKey: "default",
    host: process.env.REACT_APP_HOST_URL,
    basePath: "custom/client/assets/images/",
    primaryColor: "#2478CC",
  },
  formatting: {
    dateFormat: "MMM DD, YYYY",
    timeFormat: "hh:mm a",
  },
  analytics: {
    showCookieBanner: true,
    tagManager: {
      gtmId: "",
    },
    googleAnalytics: {
      id: "",
    },
    reportWebVitals: false,
  },
  forms: {
    contact: "",
  },
  integrations: [
    // {
    //   key: 'googlelogin',
    //   name: 'Google Login',
    //   types: 'authentication',
    //   settings: {},
    // },
    // {
    //   key: 'facebooklogin',
    //   name: 'Facebook Login',
    //   types: 'authentication',
    //   settings: {},
    // },
    // {
    //   key: 'paypalCommerce',
    //   name: 'Paypal Commerce',
    //   types: 'payment',
    //   settings: {
    //     clientID: '',
    //   },
    // },
  ],
  enableMultiSite: true,
};

const baseTheme = {
  headerTextColor: "#ffffff",
  bodyBackgroundColor: "#fafafa",
  darkColor: "#343a40",
  headerBackgroundColor: "#007BFF",
  secondaryButtonColor: "#a0cbec",
  secondaryButtonTextColor: "#ffffff",
  primaryColor: "#007BFF",
  themeDescription: "itiaustralia",
  infoColor: "#17a2b8",
  textLinkColor: "#337ab7",
  logo: "http://localhost/uploads/iti_logo_1b0d102759.png?",
  additionalLogo: " ",
  customCss: "", //".featureList {\r\n list-style: none;\r\n}\r\n.featureList li.tick:before {\r\n content: \"\\2713\";\r\n color: #006400;\r\n}\r\n.featureList li:before {\r\n position: absolute;\r\n margin-left: -1.3em;\r\n font-weight: 700;\r\n}\r\n.content-table {\r\n border: 1px solid #ccc;\r\n border-collapse: collapse;\r\n margin: 10px 0;\r\n padding: 0;\r\n width: 100%;\r\n table-layout: fixed;\r\n}\r\n.content-table tr {\r\n background-color: #f8f8f8;\r\n border: 1px solid #ddd;\r\n padding: 0.35em;\r\n}\r\n.content-table th {\r\n font-size: .85em;\r\n letter-spacing: .1em;\r\n text-transform: uppercase;\r\npadding: 0.625em;\r\n text-align: center\r\n}\r\n.content-table td {\r\n padding: 0.625em;\r\n text-align: center;\r\n}\r\n\r\n\r\n@media screen and (min-width: 992px){\r\n.itiaustralia.itiaustralia .slider-div{\r\n position: relative;\r\n padding: 0!important;\r\n}\r\n\r\n.itiaustralia.itiaustralia .slider-text-div{\r\n width: 50%;\r\n background: rgba(255,255,255,.9);\r\n position: absolute;\r\n bottom: 0;\r\n top: 0;\r\n right: 0;\r\n}\r\n\r\n.itiaustralia.itiaustralia .slider-text-div-even{\r\n width: 50%;\r\n background: rgba(255,255,255,.9);\r\n position: absolute;\r\n bottom: 0;\r\n top: 0;\r\n left: 0;\r\n}\r\n}\r\n\r\nfooter .ribbon-markup {\r\n width: 100%;\r\n}\r\nfooter form {\r\n display: flex;\r\n align-items: flex-end;\r\n}\r\nfooter form .row {\r\n flex: 1;\r\n margin-right: 1rem;\r\n padding-bottom: 0!important;\r\n}\r\nfooter form .btn { \r\n border: none;\r\n}\r\nfooter .uc-grid .cetBlock {\r\nalign-items: center;\r\n}\r\n.content-columns-sec .card-body div i {\r\n font-size: inherit;\r\n opacity: 1;\r\n margin: 0;\r\n}\r\n.table .thead-light th {\r\n color: #495057;\r\n background-color: #e9ecef;\r\n border-color: #dee2e6;\r\n}",
  primaryButtonColor: "#007BFF",
  warningColor: "#ffc107",
  favicon: "/custom/assets/images/theme/itiaustralia/fav.png",
  footerBackgroundColor: "#4F4E4D",
  headerBarColor: "#111111",
  lightColor: "#f8f9fa",
  themeID: "b597dd89f0e043943f185f15f9217911",
  dangerColor: "#dc3545",
  themeName: "itiaustralia",
  successColor: "#28a745",
  primaryButtonTextColor: "#ffffff",
  fontSize: "16px",
  footerTextColor: "#ffffff",
  bodyTextColor: "#333333",
  secondaryColor: "#008000",
};

const defaultConfig = {
  ...sitePreload,
  site: {
    ...sitePreload.site,
    siteName: process.env.REACT_APP_NAME,
    siteCode: process.env.REACT_APP_SITE_CODE,
    theme: baseTheme,
  },
  cmsProvider: "slatwallCMS",
  products: {
    fallbackImageCall: false,
    loginRequiredForPrice: false,
    quantityInput: "text",
  },
  seo: {
    title: "Storefront",
    titleMeta: "",
  },
  footer: {
    formLink: "",
  },
  theme: {
    ...sitePreload.theme,
    themeKey: "industrial",
    host: process.env.REACT_APP_HOST_URL,
    primaryColor: "#2478CC",
  },
  forms: {
    contact: "",
  },
};

console.log("CONFIG:", defaultConfig);

export default defaultConfig;
