import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  getSocialLogins,
  useElementContext,
} from "@ultracommerce/react-storefront/global";
import { useLoginForm } from "../../../hooks/components/Account/useLoginForm";
import { useState } from "react";

const LoginForm = ({ isCheckout = false }) => {
  const {
    CommonModule: { OauthGoogleLogin, OauthFacebookLogin },
  } = useElementContext();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { formik } = useLoginForm(setIsLoading);
  const location = useLocation();
  const allowGuestCheckout = useSelector(
    (state) => state.configuration.allowGuestCheckout
  );
  const socialLogins = useSelector(getSocialLogins);
  return (
    <>
      <div className="container col-lg-6 col-md-9 col-sm-12 pt-4">
        <div className="text-center">
          <h1>{t("frontend.account.myAccount")}</h1>
        </div>
        <hr />
        <p>
          Welcome to our improved web experience, offering an enhanced shopping
          experience and a host of new features designed to make ordering
          online, managing your account, and tracking existing orders easy!
        </p>
        <p>
          Users logging into our new site for the first time must click on{" "}
          <Link to="/my-account/forgotPassword">Forgot Password</Link> to reset
          their password. Need help logging in? Send us an email{" "}
          <a href="mailto:bcs.webalerts@building-controls.com">
            bcs.webalerts@building-controls.com
          </a>
        </p>

        <h2>{t("frontend.account.sign_in")}</h2>

        <form onSubmit={formik.handleSubmit}>
          <div className="row">
            <div className="mb-3">
              <label htmlFor="loginEmail">{t("frontend.account.email")}</label>
              <input
                value={formik.values.loginEmail}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                autoComplete="email"
                className="form-control"
                type="email"
                id="loginEmail"
              />
              {formik.errors.loginEmail && (
                <span className="form-error-msg">
                  {formik.errors.loginEmail}
                </span>
              )}
            </div>
            <div className="mb-3">
              <label htmlFor="loginPassword">
                {t("frontend.account.password")}
              </label>
              <input
                value={formik.values.loginPassword}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                autoComplete="current-password"
                className="form-control"
                type="password"
                id="loginPassword"
              />
              {formik.errors.loginPassword && formik.touched.loginPassword && (
                <span className="form-error-msg">
                  {formik.errors.loginPassword}
                </span>
              )}
            </div>
            <Link
              to={`/my-account/forgotPassword`}
              className="nav-link-inline font-size-sm link"
            >
              {t("frontend.account.forgot_password")}
            </Link>
          </div>
          <div className="row my-2">
            <p>
              {t("frontend.account.no_account")}
              <Link className="link mx-1" to={`/register${location.search}`}>
                {t("frontend.account.here")}
              </Link>
            </p>
          </div>
          <div className="row">
            <div className="col-lg-6 col-sm-12 mb-2">
              <button
                className="btn btn-primary btn-lg w-100"
                type="submit"
                disabled={isLoading}
              >
                {t("frontend.account.sign_in")}{" "}
                {isLoading && (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
              </button>
              {socialLogins?.map((integration) => {
                if (integration.key === "googlelogin")
                  return <OauthGoogleLogin key={integration.key} />;
                if (integration.key === "facebooklogin")
                  return (
                    <OauthFacebookLogin
                      key={integration.key}
                      buttonText={t("frontend.oauth.facebooksignin")}
                    />
                  );
                return null;
              })}
            </div>

            {allowGuestCheckout && isCheckout && (
              <div className="col-lg-6 col-sm-12">
                <Link
                  className="btn btn-primary btn-lg w-100"
                  to={`/checkout/createGuestAccount${location.search}`}
                >
                  {t("frontend.account.checkout.as.guest")}
                </Link>
              </div>
            )}
          </div>
        </form>
      </div>
    </>
  );
};

const AccountLogin = ({ isCheckout = false }) => {
  return (
    <div className="container">
      <div className="row d-flex justify-content-center">
        <LoginForm isCheckout={isCheckout} />
      </div>
    </div>
  );
};

export { AccountLogin };
