import { useComponentData } from "@ultracommerce/react-storefront/global/src/modules/Cart/Components/CartLineItem/CartLineItem.componentService";
import { CartLineItemProps } from "@ultracommerce/react-storefront/global/src/modules/Cart/Components/CartLineItem/CartLineItem.componentModel";
import { CartLineItemView } from "./CartLineItem.componentView";

const CartLineItem = (props: CartLineItemProps) => {
  const { componentData, componentService } = useComponentData(props);

  return (
    <CartLineItemView
      componentData={componentData}
      onRemoveItem={componentService.onRemoveItem}
      setItemCount={componentService.setItemCount}
      onUpdateQty={componentService.onUpdateQty}
    />
  );
};
export { CartLineItem, type CartLineItemProps };
