import Slider from "react-slick";

import { ProductImageGalleryModel } from "./ProductDetailGallery.componentModel";
import { useEffect, useRef, useState } from "react";
import { useElementContext } from "@ultracommerce/react-storefront/global";
const SLIDES_TO_SHOW = 4;

/*
Probably should move to this eventually 
https://react-slick.neostack.com/docs/example/custom-paging
*/
const useSlider = () => {
  const slider1 = useRef<Slider>();
  const slider2 = useRef<Slider>();

  const [sliders, setSliders] = useState<{ nav1?: Slider; nav2?: Slider }>({});

  useEffect(() => {
    setSliders({
      nav1: slider1.current || undefined,
      nav2: slider2.current || undefined,
    });
  }, []);

  return { sliders, slider1, slider2 };
};

const ProductDetailGalleryView = ({
  componentData: { filterImages, ImageAltTag },
}: {
  componentData: ProductImageGalleryModel & { ImageAltTag?: string };
}) => {
  const { sliders, slider1, slider2 } = useSlider();
  const {
    CommonModule: { SWImage },
  } = useElementContext();

  return (
    <div
      className="row"
      data-mdb-zoom-effect="true"
      data-mdb-auto-height="primaryProductImage"
      style={{ maxWidth: "500px" }}
    >
      <div className="preview-item active mb-3" id="first">
        <Slider
          arrows={false}
          asNavFor={sliders.nav2}
          ref={(slider) => (slider1.current = slider || undefined)}
        >
          {!!filterImages &&
            filterImages.map(
              ({ resizedImagePaths, originalFilename }, index) => {
                return (
                  <SWImage
                    key={`${originalFilename}-${index}`}
                    customPath="/"
                    src={resizedImagePaths?.at(0)}
                    className="image-zoom w-100 mx-auto"
                    alt={index === 0 ? ImageAltTag : undefined}
                    style={{ maxWidth: "500px" }}
                  />
                );
              }
            )}
        </Slider>
      </div>
      <div className="slider_container" id="primaryProductImageRow">
        {filterImages?.length > 1 && (
          <Slider
            arrows={false}
            infinite={filterImages.length > SLIDES_TO_SHOW}
            asNavFor={sliders.nav1}
            ref={(slider) => (slider2.current = slider || undefined)}
            slidesToShow={SLIDES_TO_SHOW}
            swipeToSlide={true}
            focusOnSelect={true}
          >
            {filterImages.map(
              ({ resizedImagePaths, originalFilename }, index) => {
                return (
                  <div>
                    <SWImage
                      key={`${originalFilename}-${index}`}
                      customPath="/"
                      src={resizedImagePaths[0]}
                      className="w-100 mx-auto clickable"
                      alt="Product"
                      style={{ maxWidth: "100px", borderRadius: "14px" }}
                    />
                  </div>
                );
              }
            )}
          </Slider>
        )}
      </div>
    </div>
  );
};
export { ProductDetailGalleryView };
