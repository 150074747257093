import { useTranslation } from "react-i18next";
import {
  useFormatCurrency,
  useFormatDate,
  useOrderHistoryList,
} from "@ultracommerce/react-storefront/global";
import { useElementContext } from "@ultracommerce/react-storefront/global";
import { Link } from "react-router-dom";
import { useOrderInvoicePdf } from "../../../hooks/components/Account/useOrderInvoicePdf";
import { useEffect } from "react";
import ContentLoader from "react-content-loader";

const Loader = () => (
  <ContentLoader viewBox="0 0 500 165">
    {Array.apply(null, new Array(10)).map((_, i) => (
      <rect x="0" y={24 * i} rx="4" ry="4" width="100%" height="20" />
    ))}
  </ContentLoader>
);

const OrderStatus = ({ type = "info", text }) => {
  return <span className={`badge bg-${type} m-0`}>{text}</span>;
};

const OrderListItem = (props) => {
  const {
    orderNumber,
    orderID,
    orderOpenDateTime,
    orderStatusType_typeName,
    p21OrderNumber,
    calculatedTotal,
    orderPayments_purchaseOrderNumber,
    remoteID
  } = props;

  const [formatCurrency] = useFormatCurrency({});

  const [formateDate] = useFormatDate();
  

  const { base64PDF, setOrderID, isFetching } = useOrderInvoicePdf();

  useEffect(() => {
    if (!isFetching) {
      const downloadLink = document.createElement("a");

      downloadLink.href = base64PDF;

      downloadLink.download = `${orderID}.pdf`;

      downloadLink.click();
    }
  }, [orderID, isFetching, base64PDF]);

  return (
    <tr>
      <th>
        {orderID && (
          orderNumber !== remoteID ? (<Link
            className="nav-link-style font-weight-medium font-size-sm"
            to={`/my-account/orders/${orderID}`}
          >
            {orderNumber}
          </Link>) : "--"
        )}

        <br />
      </th>
      <th>
          {orderNumber === remoteID ? (<Link
            className="nav-link-style font-weight-medium font-size-sm"
            to={`/my-account/orders/${orderID}`}
          >
            {remoteID}
          </Link>) : "--"}
      </th>
      
      <td>{orderPayments_purchaseOrderNumber !== " " ? orderPayments_purchaseOrderNumber :  "Credit Card" }</td>
          
      <td>{formateDate(orderOpenDateTime)}</td>

      <td>
        <OrderStatus text={orderStatusType_typeName} />
      </td>

      <td>{formatCurrency(calculatedTotal)}</td>

      <td>
        <button
          className="link-button link"
          onClick={() => {
            setOrderID(orderID);
          }}
        >
          Download Invoice
        </button>
      </td>
    </tr>
  );
};

const OrderHistoryList = () => {
  const { t } = useTranslation();

  const {
    CommonModule: { ListingPagination, AccountToolBar, AccountContent },
  } = useElementContext();

  const { search, setSearchTerm, keyword, orders, totalPages, currentPage } =
    useOrderHistoryList({});

  return (
    <>
      <AccountContent />

      <AccountToolBar
        term={keyword}
        updateTerm={setSearchTerm}
        search={search}
      />

      <div className="table-responsive font-size-md">
        <table className="table table-striped table-bordered ">
          <thead>
            <tr>
              <th>{t("frontend.account.order.heading")}</th>
              
              <th>{t("frontend.account.order.branch")}</th>

              <th>{t("frontend.account.order.po")}</th>

              <th>{t("frontend.account.order.date")}</th>

              <th>{t("frontend.account.order.status")}</th>

              <th> {t("frontend.account.order.total")}</th>
              
              <th className="text-center"><i className="bi bi-download h3"></i></th>
            </tr>
          </thead>

          <tbody>
            {orders.isLoaded &&
              orders.data.ordersOnAccount?.map((order, index) => {
                return <OrderListItem key={index} {...order} />;
              })}
          </tbody>
        </table>
        {orders.isFetching && <Loader />}
      </div>

      <ListingPagination
        recordsCount={orders.data.records}
        currentPage={currentPage}
        totalPages={totalPages}
        setPage={search}
      />
    </>
  );
};

const AccountOrderHistory = ({ crumbs, title, orders }) => {
  const { t } = useTranslation();

  const {
    CommonModule: { AccountLayout },
  } = useElementContext();

  return (
    <AccountLayout title={t("frontend.account.account_order_history")}>
      <OrderHistoryList orders={orders} />
    </AccountLayout>
  );
};

export { AccountOrderHistory };
