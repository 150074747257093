import { useElementContext } from "@ultracommerce/react-storefront/global";
import { LocationAddressProps } from "@ultracommerce/react-storefront/global/src/modules/Location/Controls/LocationAddress";

export interface LocationDetailProps extends LocationAddressProps {
  customerName? :string;
  locationType?: string;
  phoneNo?: string;
  faxNo?: string;
  websiteURL?: string;
}

export const LocationDetail = ({ customerName, locationType, phoneNo, faxNo, websiteURL, ...addressProps }: LocationDetailProps) => {
  const { LocationModule } = useElementContext();
  return (
    <>
      <h5 className="card-title text-uppercase">{customerName}</h5>
      {!!locationType && <h6 className="card-subtitle mb-2 text-muted">{locationType}</h6>}
      <LocationModule.CoreControl.LocationAddress {...addressProps} />
      {!!phoneNo && (
        <div>
          <i className="bi bi-telephone" />
          <span style={{ paddingLeft: "10px" }}>
            <a href={`tel:${phoneNo}`} className="card-link">
              {`${phoneNo}`}
            </a>
          </span>
        </div>
      )}
      {!!faxNo && (
        <div title="Fax">
          <i className="bi bi-printer " />
          <span style={{ paddingLeft: "10px" }} className="card-link">
            {faxNo}
          </span>
        </div>
      )}
      {!!websiteURL && (
        <div title="WebsiteURL">
          <i className="bi bi-globe " />
          <span style={{ paddingLeft: "10px" }}>
            <a href={websiteURL} className="card-link" target="_new">
              {websiteURL.replace(/(^\w+:|^)\/\//, "")}
            </a>
          </span>
        </div>
      )}
    </>
  );
};
