import { useMemo } from "react";
import {
  ProductImageGalleryModel,
  ProductImageGalleryProps,
} from "./ProductDetailGallery.componentModel";

export const useComponentData = (props: ProductImageGalleryProps) => {
  const componentData = useMemo(
    () => new ProductImageGalleryModel({ props }),
    [props]
  );

  return { componentData };
};
