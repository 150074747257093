import { useComponentData } from "./ProductDetailGallery.componentService";
import { ProductImageGalleryProps } from "./ProductDetailGallery.componentModel";
import { ProductDetailGalleryView } from "./ProductDetailGallery.componentView";

const ProductDetailGallery = (props: ProductImageGalleryProps & { ImageAltTag?: string }) => {
  const { componentData } = useComponentData(props);
  (componentData as any).ImageAltTag = props.ImageAltTag
  return <ProductDetailGalleryView componentData={componentData} />;
};
export { ProductDetailGallery, type ProductImageGalleryProps };
