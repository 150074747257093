import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ProductTypeListModel } from "@ultracommerce/react-storefront/global/src/modules/Product/Components/ProductTypeList/ProductTypeList.componentModel";
import {
  sortProductTypes,
  useElementContext,
  getProductTypeRoute,
} from "@ultracommerce/react-storefront/global";

const ProductTypeListView = ({
  componentData: {
    childProductTypes,
    showOnlyDirectChild = false,
    isFetching = true,
    customStyleClasses,
    rangeMetaData,
  },
}: {
  componentData: ProductTypeListModel;
}) => {
  const productTypeRoute = useSelector(getProductTypeRoute);
  const {
    CommonModule: { SWImage, Spinner },
  } = useElementContext();

  if (isFetching)
    return (
      <div
        className={`container bg-light box-shadow-lg rounded-lg p-5 ${customStyleClasses}`}
      >
        <Spinner />
      </div>
    );

  if (!childProductTypes.length) return null;

  return (
    <div className={`container pb-4 pb-sm-5 ${customStyleClasses}`}>
      <div className="product-grid">
        {sortProductTypes(childProductTypes, rangeMetaData).map(
          ({
            productTypeID,
            productTypeName,
            imageFile,
            urlTitle,
            childProductTypes,
          }: any) => {
            let customImagePath = "";
            let imageFileName = "";
            if (imageFile && !imageFile.startsWith("http")) {
              const imageParts = imageFile.split("/");
              imageFileName = imageParts.pop();
              customImagePath = imageParts.join("/") + "/";
            } else {
              imageFileName = imageFile.includes("-thumb.")
                ? imageFile
                : imageFile.replace(/(\.[\w\d_-]+)$/i, "-thumb$1");
            }

            const rangeData = rangeMetaData[urlTitle.split("/").pop()];
            productTypeName = rangeData?.rangeName || productTypeName;
            imageFileName = rangeData?.rangeLogo || imageFileName;

            return (
              <Link
                className="product-type-child product-link"
                key={productTypeID}
                to={`/${productTypeRoute}/${urlTitle}`}
                onClick={() =>
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  })
                }
              >
                <div className="product-card">
                  <SWImage
                    className="product-image"
                    customPath={customImagePath}
                    src={imageFileName}
                    alt={productTypeName}
                    type="productType"
                  />
                  <div className="product-title">
                    <h4 className="text-center">{productTypeName}</h4>
                    {!showOnlyDirectChild && childProductTypes?.length > 0 && (
                      <ul className="list-unstyled font-size-sm mb-0">
                        {sortProductTypes(childProductTypes, rangeMetaData).map(
                          ({
                            productTypeID,
                            productTypeName,
                            urlTitle,
                          }: any) => {
                            const rangeData =
                              rangeMetaData[urlTitle.split("/").pop()];
                            productTypeName =
                              rangeData?.rangeName || productTypeName;
                            return (
                              <li
                                className="d-flex align-items-center justify-content-between"
                                key={productTypeID}
                              >
                                <Link
                                  to={`/${productTypeRoute}/${urlTitle}`}
                                  className="link-button nav-link-style d-flex align-items-center justify-content-between text-left"
                                >
                                  <i className="bi bi-chevron-circle-right pr-2"></i>
                                  {productTypeName}
                                </Link>
                              </li>
                            );
                          }
                        )}
                      </ul>
                    )}
                  </div>
                </div>
              </Link>
            );
          }
        )}
      </div>
    </div>
  );
};

export { ProductTypeListView };
