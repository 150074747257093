import { getBrandRoute, getProductRoute } from "@ultracommerce/react-storefront/global";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { SearchProduct, SearchSku } from "@ultracommerce/react-storefront/global/src/interface/SearchProduct";
import { removeWishlistItem } from "@ultracommerce/react-storefront/global";
import { toast } from "react-toastify";
import { useElementContext } from "@ultracommerce/react-storefront/global";
import { ProductPrice } from "../../Product/Components/ProductPrice/ProductPrice.component";
import { useProductLivePrice } from "../../../hooks/components/ProductDetail/useProductLivePricing";

interface ExtendedSearchSku extends SearchSku {
    product_productID: string;
}

interface CardConfiguration {
    showInput: boolean;
    showPrice: boolean;
    showSkuCode: boolean;
    showProductCode: boolean;
    showBrand: boolean;
    enableFavorites: boolean;
    buttons: [];
    removeFromList: boolean;
    orderTemplate?: { orderTemplateID?: string; orderTemplateName?: string };
}

export interface SkuCardWithDetailProps {
    product: ExtendedSearchSku;
    cardConfiguration?: CardConfiguration;
}

const SkuCardWithDetail = ({
    product,
    cardConfiguration = {
        showInput: true,
        showPrice: true,
        showSkuCode: true,
        showProductCode: false,
        showBrand: false,
        enableFavorites: false,
        buttons: [],
        removeFromList: false,
        orderTemplate: {},
    },
}: SkuCardWithDetailProps) => {
    const {
        skuName: name,
        skuDescription: description,
        options,
        skuCode,
        skuID,
        product_urlTitle: urlTitle,
        settings,
        product_brand_brandName: brandName,
        product_brand_urlTitle: brandUrlTitle,
        product_productCode: productCode,
        product_productID: productID
    } = product;
    const {
        ProductModule,
        ProductCardModule,
        CommonModule: { Truncate },
    } = useElementContext();
    const dispatch = useDispatch();
    const { isLivePriceFetching, listPrice, salePrice, QOH, restrictedFlag, skuAllowBackorderFlag } = useProductLivePrice({ productID, dispatch });
    const isBackOrderedStockExist = QOH > 0 || skuAllowBackorderFlag === "1" ? true : false;
    const skuUom = options && options?.length > 0 ? options[0].optionGroupName : "";
    const productRoute = useSelector(getProductRoute);
    const brandRoute = useSelector(getBrandRoute);
    const productLink = `/${productRoute}/${urlTitle}` + (skuID?.length ? `?skuid=${skuID}` : "");
    const removeItemFromList = () => {
        dispatch<any>(removeWishlistItem(skuID, cardConfiguration?.orderTemplate?.orderTemplateID)).then((res: any) => {
            if (res.isSuccess()) {
                toast.success("Item removed from the list successfully!");
            }
        });
    };

    return (
        <div className="card productCardWithDetail p-3 my-2">
            <div
                className="d-md-grid d-sm-flex flex-column flex-md-row align-items-center align-items-md-start"
                style={{ gridTemplateColumns: "1fr 4fr" }}
            >
                <div className="d-flex flex-column align-items-center flex-shrink-0 h-100 w-auto">
                    <Link to={productLink} style={{ maxWidth: 160 }}>
                        <ProductCardModule.CoreControl.ProductCardImage product={product} />
                    </Link>
                    {skuID && cardConfiguration.enableFavorites && (
                        <ProductModule.CoreComponents.HeartButton skuID={skuID} className=" align-self-center link btn-wishlist" />
                    )}
                </div>

                <div className="w-100">
                    <div
                        className="d-sm-flex d-md-grid flex-wrap align-content-start gap-4"
                        style={{ gridTemplateColumns: "2fr 2fr" }}
                    >
                        <div className="col-12">
                            <Link to={productLink} className="product-name">
                                <h5 className="mx-1 pt-2">{name}</h5>
                            </Link>
                            {cardConfiguration?.showBrand && (
                                <Link to={`/${brandRoute}/${brandUrlTitle}`} className="text-capitalize product-brand mx-1">
                                    {brandName}
                                </Link>
                            )}
                            <Truncate lineClamp="5">
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: description,
                                    }}
                                />
                            </Truncate>
                        </div>
                        <div className="col-12">
                            {cardConfiguration.showProductCode && productCode && <div>Mfr. Model: {productCode}</div>}
                            {cardConfiguration.showSkuCode && (
                                <>
                                    <div>SKU : {skuCode}</div>
                                    {skuUom !== "" && <div>UOM : {skuUom}</div>}
                                </>
                            )}
                            {cardConfiguration.showPrice && !isLivePriceFetching && (
                                <span className="d-flex pt-2">
                                    <ProductPrice
                                        salePrice={salePrice}
                                        listPrice={listPrice}
                                        showPriceForUserType={settings?.skuShowPriceForUserType}
                                    />
                                </span>
                            )}

                            {!restrictedFlag && isBackOrderedStockExist && <ProductCardModule.CoreComponents.ProductCardActions
                                type="dropdown"
                                sku={product}
                                cardConfiguration={cardConfiguration}
                            />}
                            {cardConfiguration?.removeFromList && (
                                <span className="btn-link" role="button" onClick={() => removeItemFromList()}>
                                    Remove item from {cardConfiguration?.orderTemplate?.orderTemplateName}
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

interface ExtendedSearchProduct extends SearchProduct {
    BCSManufacturerPartNumber: any;
    // OptionSet?: IOptionSet[];
}

export interface ProductCardWithDetailProps {
    product: ExtendedSearchProduct;
    cardConfiguration?: CardConfiguration;
}

const ProductCardWithDetail = ({
    product,
    cardConfiguration = {
        showInput: true,
        showPrice: true,
        showSkuCode: true,
        showProductCode: false,
        showBrand: false,
        enableFavorites: false,
        buttons: [],
        removeFromList: false,
        orderTemplate: {},
    },
}: ProductCardWithDetailProps) => {
    const {
        // productName: name,
        productID,
        productDescription: description,
        optionGroups: options,
        defaultSku_skuCode: skuCode,
        defaultSku_skuID: skuID,
        urlTitle,
        settings,
        brand_brandName: brandName,
        brand_urlTitle: brandUrlTitle,
        productCode,
        BCSManufacturerPartNumber,
        skus
    } = product;
    const {
        ProductCardModule,
        ProductModule,
        CommonModule: { Truncate },
    } = useElementContext();
    const dispatch = useDispatch();
    const { isLivePriceFetching, listPrice, salePrice, QOH, restrictedFlag, skuAllowBackorderFlag } = useProductLivePrice({ productID, dispatch });
    const skuUom = options && options?.length > 0 ? options[0].optionGroupName : "";
    const productRoute = useSelector(getProductRoute);
    const brandRoute = useSelector(getBrandRoute);
    const productLink = `/${productRoute}/${urlTitle}` + (skuID?.length ? `?skuid=${skuID}` : "");
    const defaultSku = skus.find((sku) => sku.skuID === skuID) || skus[0];

    const removeItemFromList = () => {
        dispatch<any>(removeWishlistItem(skuID, cardConfiguration?.orderTemplate?.orderTemplateID)).then((res: any) => {
            if (res.isSuccess()) {
                toast.success("Item removed from the list successfully!");
            }
        });
    };
    const isBackOrderedStockExist = QOH > 0 || skuAllowBackorderFlag === "1" ? true : false;
    return (
        <div className="card productCardWithDetail p-3 my-2">
            <div
                className="d-md-grid d-sm-flex flex-column flex-md-row align-items-center align-items-md-start"
                style={{ gridTemplateColumns: "1fr 4fr" }}
            >
                <div className="d-flex flex-column align-items-center flex-shrink-0 h-100 w-auto">
                    <Link to={productLink} style={{ maxWidth: 160 }}>
                        {defaultSku && <ProductCardModule.CoreControl.ProductCardImage product={defaultSku} />}
                    </Link>
                    {skuID && cardConfiguration.enableFavorites && (
                        <ProductModule.CoreComponents.HeartButton skuID={skuID} className=" align-self-center link btn-wishlist" />
                    )}
                </div>

                <div className="flex-grow-1 w-100">
                    <div
                        className="d-sm-flex d-md-grid flex-wrap align-content-start gap-4"
                        style={{ gridTemplateColumns: "2fr 2fr" }}
                    >
                        <div className="col-12">
                            <Link to={productLink} className="product-name">
                                <h5 className="pt-2">{BCSManufacturerPartNumber ? BCSManufacturerPartNumber: productCode}</h5>
                            </Link>
                            {cardConfiguration?.showBrand && (
                                <Link to={`/${brandRoute}/${brandUrlTitle}`} className="text-capitalize product-brand mx-1">
                                    {brandName}
                                </Link>
                            )}
                            <Truncate lineClamp="5">
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: description,
                                    }}
                                />
                            </Truncate>
                        </div>
                        <div className="col-12">
                            {cardConfiguration.showProductCode && productCode && <div>Mfr. Model: {BCSManufacturerPartNumber ? BCSManufacturerPartNumber : productCode}</div>}
                            {cardConfiguration.showSkuCode && (
                                <>
                                    <div>SKU : {skuCode}</div>
                                    {skuUom !== "" && <div>UOM : {skuUom}</div>}
                                </>
                            )}
                            {cardConfiguration.showPrice && !isLivePriceFetching && (
                                <span className="d-flex pt-2">
                                    <ProductPrice
                                        salePrice={salePrice}
                                        listPrice={listPrice}
                                        showPriceForUserType={settings?.skuShowPriceForUserType}
                                    />
                                </span>
                            )}
                            {/* loader when price is fetching */}
                            {/* {isLivePriceFetching && (
                                <div className="py-2">
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    &nbsp; Loading your price ...
                                </div>
                            )} */}

                            {defaultSku && !restrictedFlag && isBackOrderedStockExist && (
                                <ProductCardModule.CoreComponents.ProductCardActions
                                    type="dropdown"
                                    sku={defaultSku}
                                    cardConfiguration={cardConfiguration}
                                />
                            )}
                            {cardConfiguration?.removeFromList && (
                                <span className="btn-link" role="button" onClick={() => removeItemFromList()}>
                                    Remove item from {cardConfiguration?.orderTemplate?.orderTemplateName}
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export { ProductCardWithDetail, SkuCardWithDetail };
