import { SearchBarProps } from "@ultracommerce/react-storefront/global/src/components/Header/SearchBar/SearchBar.componentModel";
import { useComponentData } from "@ultracommerce/react-storefront/global/src/components/Header/SearchBar/SearchBar.componentService";
import { SearchBarView } from "./SearchBar.componentView";

const SearchBar = (props: SearchBarProps) => {
  const { componentData, componentService } = useComponentData(props);
  return <SearchBarView componentData={componentData} {...componentService} />;
};

export { SearchBar };
