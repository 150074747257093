import axios from 'axios'
import { useEffect, useState } from 'react'
const getSdkURL = () => `${window.UC?.integrations?.commerce.moduleApiUrl || ""}/`;

const useProductLivePrice = ({ productID, dispatch }) => {
  // let productIDs = records.map(({ productID }) => productID)
  let [isFetching, setFetching] = useState(true)
  const [livePrice,setLivePrice] = useState()
  useEffect(() => {
    if (isFetching) {
      let source = axios.CancelToken.source()
      axios({
        method: 'POST',
        withCredentials: true,
        url: `${getSdkURL()}api/scope/getPriceByProductIDs`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: { productIDs: productID },
      })
        .then(response => {
          if (response?.status === 200 && response?.data?.failureActions.length === 0) {
            setLivePrice(response?.data.products?.at(0))
            // dispatch({ type: "UpdateRecords/LivePrices", payload: response?.data.products });
            setFetching(false)
            return response?.data
          }
        })
        .catch(() => {
          setFetching(false)
        })
      return () => {
        source.cancel()
      }
    }
  }, [productID, isFetching, dispatch])
  return { isLivePriceFetching: isFetching, setLivePriceFetching: setFetching,  salePrice: livePrice?.salePrice, listPrice: livePrice?.listPrice, inventoryLocations : livePrice?.inventoryData, QATS: livePrice?.calculatedQATS, QOH: livePrice?.calculatedQOH, restrictedFlag: livePrice?.restrictedProductFlag, skuAllowBackorderFlag: livePrice?.skuAllowBackorderFlag }
}
export { useProductLivePrice }