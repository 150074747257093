import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  SlatwalApiService,
  getErrorMessage,
  getUser,
  getWishLists,
  isImpersonating,
  receiveCart,
} from "@ultracommerce/react-storefront/global";

const AccountImpersonationBanner = () => {
  const { t } = useTranslation();
  const { firstName, lastName } = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  const endImpersonation = () => {
    return SlatwalApiService.account.endImpersonation().then((response) => {
      if (
        response.isSuccess() &&
        Object.keys(response.success()?.errors || {}).length
      )
        toast.error(getErrorMessage(response.success().errors));
      if (response.isSuccess()) {
        toast.success(t("frontend.account.end_impersonation_successful"));
        dispatch(receiveCart(response.success().cart));
        dispatch(getUser()).then(() => {
          dispatch(getWishLists(true));
        });
      } else {
        toast.error(t("frontend.account.end_impersonation_failed"));
      }
    });
  };
  if (!isImpersonating()) return null;
  return (
    <div className="container-fluid impersonation-banner">
      <div className="row d-flex justify-content-center align-items-center bg-primary ">
        <div className="col-auto py-2">
          <div className="">
            {t("frontend.account.account_impersonation_banner")}
            <span className="fw-bolder">
              {`, ${firstName} ${lastName}. `}{" "}
              <span
                className="link link-btn"
                onClick={(e) => {
                  e.preventDefault();
                  endImpersonation();
                }}
              >
                {t("frontend.account.end_impersonation")}
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export { AccountImpersonationBanner };
